import $ from 'jquery';
import CustomAnimation from "../animation/customAnimation";

const customAnmation = new CustomAnimation();

class ModuleKunden {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {};

  initParallax = () => {
    customAnmation.animateKundenLogos();
  };
}

export default ModuleKunden;
