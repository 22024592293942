import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CustomAnimation from "../animation/customAnimation";

const customAnmation = new CustomAnimation();

function isTouchEnabled() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

class ModuleTeam {
  init() {
    this.initListener();
    this.initParallax();
    this.onResize();
  }

  initListener = () => {
    $('.team_foto_container').mouseover(function () {
      $(this).addClass('show');
    });
    $('.team_foto_container').mouseout(function () {
      $(this).removeClass('show');
    });
  };

  initParallax = () => {
    customAnmation.animateGroup('#team h1, #team p, #team a');
    customAnmation.animateTeamFotos();
  };

  onResize = () => {
    $(window)
      .resize(function () {
        // team item
        if (isTouchEnabled() === true) {
          $('.team_foto_container').addClass('isTouch');
        } else {
          $('.team_foto_container').removeClass('isTouch');
        }

        if ($(window).width() > 768) {
          $('.container_text').removeClass('container');
        } else {
          $('.container_text').addClass('container');
        }
      })
      .resize();
  };
}

export default ModuleTeam;
