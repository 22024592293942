import $ from 'jquery';

function trackButtonClick(elementName) {
  console.log(
    `trackClick() : ${elementName.data('tracking-event')} / ${elementName.data(
      'tracking-category'
    )} / ${elementName.data('tracking-label')}`
  );

  gtag('event', elementName.data('tracking-event'), {
    event_category: elementName.data('tracking-category'),
    event_label: elementName.data('tracking-label')
  });
}
function trackButtonOver(elementName) {
  console.log(
    `trackButtonOver() : ${elementName.data('tracking-event')} / ${elementName.data(
      'tracking-category'
    )} / ${elementName.data('tracking-label')}`
  );

  gtag('event', elementName.data('tracking-event'), {
    event_category: elementName.data('tracking-category'),
    event_label: elementName.data('tracking-label')
  });
}

class Tracking {
  init() {
    this.initListener();
  }

  initListener = () => {
    // navi
    $('.language_button, .navigation_socialmedia_icon ,.menu_item').click(function () {
      trackButtonClick($(this));
    });

    // header
    $('.header_logo_link, .header_scroll_button, .header_bubble_link').click(function () {
      trackButtonClick($(this));
    });

    // news
    $('#news a').click(function () {
      console.log(
          `trackClick() : Click News Button / News / News-Button}`
      );

      gtag('event', 'Click News Button', {
        event_category: 'News',
        event_label: 'News-Button'
      });
    });

    // leistungen
    $('.leistungen_item').click(function () {
      console.log(
        `trackClick() : Click Leistungen Element / Leistungen / ${$(this).data('tracking-label')}`
      );

      gtag('event', 'Click Leistungen Element', {
        event_category: 'Leistungen',
        event_label: $(this).data('tracking-label')
      });
    });

    // referenzen
    $('.referenzen_project_item').click(function () {
      trackButtonClick($(this));
    });

    // team
    $('.team_foto_container').mouseover(function () {
      console.log(`trackClick() : MausOver Team Foto / Team /${$(this).data('tracking-label')}`);

      gtag('event', 'MausOver Team Foto', {
        event_category: 'Team',
        event_label: $(this).data('tracking-label')
      });
    });

    // jobs
    $('.jobs_button').click(function () {
      trackButtonClick($(this));
    });

    // podcast
    $('.podcast_button').click(function () {
      trackButtonClick($(this));
    });

    // footer
    $('.footer_icon_link, .footer_link_impressum, .footer_link_datenschutz').click(function () {
      trackButtonClick($(this));
    });
  };

  trackNavigation(action) {
    console.log(`trackNavigation() : Navigation Layer Toggle / Navigation / ${action}`);

    gtag('event', 'Navigation Layer Toggle', {
      event_category: 'Navigation',
      event_label: action
    });
  }

  trackReferenzenFilter(trackingLabel) {
    console.log(
      `trackReferenzenFilter() : Referenzen Filter Auswahl / Referenzen / ${trackingLabel}`
    );

    gtag('event', 'Referenzen Filter Auswahl', {
      event_category: 'Referenzen',
      event_label: trackingLabel
    });
  }

  trackFotoChange(sliderId, index) {
    console.log(`trackFotoChange() / FotoSlider ${sliderId} >  Foto ${index}`);


    gtag('event', 'Foto Slider Change', {
      event_category: 'FotoSlider',
      event_label: `FotoSlider ${sliderId} >  Foto ${index}`
    });
  }

  trackWerteChange(index) {
    console.log(`trackWerteChange() / WerteSlider >  Werte Kennwerte Seite ${index + 1}`);


    gtag('event', 'Werte Slider Change', {
      event_category: 'Werte',
      event_label: `Werte-Kennwerte Seite ${index}${1}`
    });
  }
}

export default Tracking;
