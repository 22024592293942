import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class CustomAnimation {
  init() {
    this.initListener();
  }

  animateBubble(element, elementTrigger, yPos) {
    gsap.to(element, {
      y: yPos,
      ease: 'none',
      scrollTrigger: {
        trigger: elementTrigger,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 0,
        markers: false
      }
    });
  }

  animateImage(element, elementTrigger) {
    gsap.from(element, {
      scale: '.9',
      y: '20%',
      ease: 'none',
      scrollTrigger: {
        trigger: elementTrigger,
        start: 'top 80%',
        end: 'top 10%',
        scrub: 0,
        markers: false
      }
    });
  }

  animateText(element) {
    gsap.from(element, {
      y: 75,
      scrollTrigger: {
        trigger: element,
        start: 'top 120%',
        end: 'top 65%',
        markers: false,
        scrub: 0
      }
    });
  }

  animateGroup(elements) {
    const elementGroup = gsap.utils.toArray(elements);
    elementGroup.forEach(element => {
      gsap.from(element, {
        y: 75,
        scrollTrigger: {
          trigger: element,
          start: 'top 120%',
          end: 'top 65%',
          markers: false,
          scrub: true
        }
      });
    });
  }

  animateLeistungenItems() {
    gsap.defaults({ ease: 'power3' });
    gsap.set('.leistungen_item', { y: 50, opacity: 0 });
    ScrollTrigger.batch('.leistungen_item', {
      interval: 0.1, // time window (in seconds) for batching to occur
      onEnter: (batch) => gsap.to(batch, {opacity: 1, y: 0, stagger: { each: 0.15, grid: [1, 3] }, overwrite: true}),
      onEnterBack: (batch) => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15, overwrite: true }),
      onLeaveBack: (batch) => gsap.set(batch, { opacity: 0, y: 50, overwrite: true }),
      end: '50% center'
    });
  }

  animateKundenLogos() {
    ScrollTrigger.defaults({
      start: 'top 100%',
      end: 'bottom 0%',
      markers: false,
      scrub: 0
    });
    gsap
      .timeline({ paused: true, scrollTrigger: { trigger: '.kunden_logo_row_01' } })
      .fromTo('.kunden_logo_row_01', { x: 100 }, { x: -50 });

    gsap
      .timeline({ paused: true, scrollTrigger: { trigger: '.kunden_logo_row_02' } })
      .fromTo('.kunden_logo_row_02', { x: 120 }, { x: -60 });

    gsap
      .timeline({ paused: true, scrollTrigger: { trigger: '.kunden_logo_row_03' } })
      .fromTo('.kunden_logo_row_03', { x: 140 }, { x: -70 });

    gsap
      .timeline({ paused: true, scrollTrigger: { trigger: '.kunden_logo_row_04' } })
      .fromTo('.kunden_logo_row_04', { x: 160 }, { x: -80 });
  }

  animateTeamFotos() {
    const items = gsap.utils.toArray('.team_foto_container');
    items.forEach((teamFotoImage) => {
      gsap.from(teamFotoImage, {
        scale: 0.9,
        y: 50,
        scrollTrigger: {
          trigger: teamFotoImage,
          start: 'top 110%',
          end: 'top 50%',
          markers: false,
          scrub: 0
        }
      });
    });
  }

  animateJobButtons() {
    const items = gsap.utils.toArray('.jobs_button');
    items.forEach((jobsButton) => {
      gsap.from(jobsButton, {
        y: 50,
        scrollTrigger: {
          trigger: jobsButton,
          start: 'top 100%',
          end: 'top 70%',
          markers: false,
          scrub: 0
        }
      });
    });
  }

  animateContactItems() {
    gsap.defaults({ ease: 'power3' });
    gsap.set('.contact_item', { y: 50, opacity: 0 });
    ScrollTrigger.batch('.contact_item', {
      interval: 0.1, // time window (in seconds) for batching to occur
      onEnter: (batch) => gsap.to(batch, {opacity: 1, y: 0, stagger: { each: 0.15, grid: [1, 3] }, overwrite: true }),
      onEnterBack: (batch) => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15, overwrite: true }),
      onLeaveBack: (batch) => gsap.set(batch, { opacity: 0, y: 50, overwrite: true }),
      end: '50% center'
    });
  }

  animateWerbefilmImage() {
    gsap.from('.werbefilm_content', {
      y: 150,
      scale: .8,
      scrollTrigger: {
        trigger: '.werbefilm_content',
        start: 'top 120%',
        end: 'top 0%',
        markers: false,
        scrub: 0
      }
    });
  }

  initListener = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.content_image_mask .content_image').forEach((section, i) => {
      gsap.fromTo(
        section,
        {
          // y: () => -200//-section.offsetHeight / 4
        },
        {
          scrollTrigger: {
            trigger: section.parentElement,
            scrub: true
          },
          y: section.parentElement.offsetHeight - section.offsetHeight,
          ease: 'none'
        }
      );
    });

    gsap.defaults({ ease: 'power3' });
    gsap.set('.leistungen_item_', { y: 50, opacity: 0 });
    ScrollTrigger.batch('.leistungen_item_', {
      interval: 0.1, // time window (in seconds) for batching to occur
      onEnter: (batch) => gsap.to(batch, {opacity: 1, y: 0, stagger: { each: 0.15, grid: [1, 3] }, overwrite: true}),
      onEnterBack: (batch) => gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15, overwrite: true }),
      onLeaveBack: (batch) => gsap.set(batch, { opacity: 0, y: 50, overwrite: true }),
      end: '50% center'
    });
  };
}

export default CustomAnimation;
