import $ from 'jquery';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class ModuleGreen {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {};

  initParallax = () => {
    customAnmation.animateGroup('#green h1, #green p');
  };
}

export default ModuleGreen;
