import $ from 'jquery';
import 'isotope-packery';
import 'isotope-cells-by-row';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class moduleContact {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {};

  initParallax = () => {
    customAnmation.animateGroup('#contact h1, #contact .contact_text p');
    customAnmation.animateImage('.content_image_contact', '#contact');
    customAnmation.animateContactItems();
  };
}

export default moduleContact;
