import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Flickity from 'flickity';
import Tracking from '../tracking/tracking';

const tracking = new Tracking();

class ModuleFotos {
  init() {
    this.initListener();
    this.initFlickity();
    this.initParallax();
  }

  initListener = () => {};

  initFlickity = () => {
    const flickity1 = new Flickity('.slick_caroussel_foto_01', {
      cellAlign: 'left',
      contain: true,
      imagesLoaded: false,
      percentPosition: false,
      freeScroll: true,
      pageDots: false,
      wrapAround: true,
      lazyLoad: 3
    });
    flickity1.on('change', function (index) {
      tracking.trackFotoChange(1, index);
    });
  };

  initParallax = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from('.slick_caroussel_foto_01', {
      x: 100,
      scrollTrigger: {
        trigger: '.slick_caroussel_foto_01',
        start: 'top 100%',
        end: 'bottom 30%',
        markers: false,
        scrub: 0.5
      }
    });
  };
}

export default ModuleFotos;
