import $ from 'jquery';
import ModuleNews from '../modules/moduleNews';
import ModuleContact from '../modules/moduleContact';
import ModuleMap from '../modules/moduleMap';
import ModuleFooter from '../modules/moduleFooter';
import ModuleModal from '../modules/moduleModal';
import CustomAnimation from '../animation/customAnimation';
import Tracking from '../tracking/tracking';
import ModuleSubHeader from '../modules/moduleSubHeader';

class Newspage {
  init() {
    const moduleSubHeader = new ModuleSubHeader();
    moduleSubHeader.init();

    const moduleNews = new ModuleNews();
    moduleNews.init();

    const moduleContact = new ModuleContact();
    moduleContact.init();

    const moduleMap = new ModuleMap();
    moduleMap.init();

    const moduleFooter = new ModuleFooter();
    moduleFooter.init();

    const moduleModal = new ModuleModal();
    moduleModal.init();

    const customAnimation = new CustomAnimation();
    customAnimation.init();

    const tracking = new Tracking();
    tracking.init();
  }
}

export default Newspage;
