import $ from 'jquery';
import videojs from 'video.js';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

let playVideo = false;
let winWidth;

function calculateDistance(elem, mouseX, mouseY) {
  return Math.floor(
    Math.sqrt(
      // eslint-disable-next-line no-restricted-properties
      Math.pow(mouseX - (elem.offset().left + elem.width() / 2), 2) +
        // eslint-disable-next-line no-restricted-properties
        Math.pow(mouseY - (elem.offset().top + elem.height() / 2), 2)
    )
  );
}

function magnetize(el, e, o) {
  const mX = e.pageX;
  const mY = e.pageY;
  const item = $(el);
  let offset = o;

  if (winWidth < 768) {
    offset /= 2;
  } else {
    offset = o;
  }

  const customDist = item.data('dist') * offset || offset * 6;
  const centerX = item.offset().left + item.width() / 2;
  const centerY = item.offset().top + item.height() / 2;

  const deltaX = Math.floor(centerX - mX) * -0.45;
  const deltaY = Math.floor(centerY - mY) * -0.45;

  const distance = calculateDistance(item, mX, mY);

  if (distance < customDist) {
    gsap.to(item, 0.5, { y: deltaY, x: deltaX, scale: 1.1 });
    item.addClass('magnet');
  } else {
    gsap.to(item, 0.6, { y: 0, x: 0, scale: 1 });
    item.removeClass('magnet');
  }
}

class ModuleHeader {
  init() {
    this.animate();
    this.initListener();
    this.initVideo();
    this.initParallax();
    this.onScroll();
    this.onResize();
  }

  animate() {
    this.timeout = setTimeout(function () {
      $('.header_video_container').addClass('show');
    }, 500);

    this.timeout = setTimeout(function () {
      $('.header_headline').addClass('show');
    }, 1000);

    this.timeout = setTimeout(function () {
      $('.header_bubble_news').addClass('show');
    }, 1400);

    this.timeout = setTimeout(function () {
      $('.header_bubble_phone').addClass('show');
    }, 1800);

    setTimeout(function () {
      $('.header_bubble_mail').addClass('show');
    }, 2200);
  }

  initListener = () => {
    $(document).on('mousemove touch', function (e) {
      magnetize($('.header_bubble_news'), e, 12);
      magnetize($('.header_bubble_phone'), e, 9);
      magnetize($('.header_bubble_mail'), e, 7);
    });

    $('.header_bubble_phone').on('mouseover', function () {});
    $('.header_bubble_phone').on('mouseout', function () {});
  };

  initParallax = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to('.home_video', {
      scrollTrigger: {
        trigger: '.header_video_container',
        start: 'center 50%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0
      },
      y: 200
    });

    /*
    gsap.to('.header_headline', {
      scrollTrigger: {
        trigger: '.header_content',
        start: 'center 50%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0.5
      },
      scale: 1 // 0.8
    }); */

    gsap.to('.header_bubbles', {
      scrollTrigger: {
        trigger: '.header_bubbles',
        start: 'bottom 90%',
        end: 'bottom 0%',
        markers: false,
        scrub: 0.5
      },
      scale: 0.8
    });

    gsap.to('.logo', {
      scrollTrigger: {
        trigger: '#header',
        start: 'center center',
        end: 'bottom 20%',
        markers: false,
        scrub: 0.5
      },
      scale: 0.7
    });

    gsap.to('.top-nav', {
      scrollTrigger: {
        trigger: '#header',
        start: 'center 41%',
        end: 'center 40%',
        markers: false,
        scrub: 0
      },
      autoAlpha: 0
    });

    gsap.to('.header_bubble_news', {
      scrollTrigger: {
        trigger: '#header',
        start: 'bottom 90%',
        end: 'bottom 80%',
        markers: false,
        scrub: 0
      },
      autoAlpha: 0,
      scale: 0.5
    });

    gsap.to('.header_bubble_phone', {
      scrollTrigger: {
        trigger: '#green',
        start: 'bottom 90%',
        end: 'bottom 80%',
        markers: false,
        scrub: 0
      },
      autoAlpha: 0,
      scale: 0.5
    });

    gsap.to('.header_bubble_mail', {
      scrollTrigger: {
        trigger: '#green',
        start: 'bottom 90%',
        end: 'bottom 80%',
        markers: false,
        scrub: 0
      },
      autoAlpha: 0,
      scale: 0.5
    });
  };

  initVideo = () => {
    $('.home_video')[0].pause();

    /*const videoFile = 'images/header/saubere_filme_header_video.mp4';
    $('.home_video source').attr('src', videoFile);
    $('.home_video')[0].load();
    $('.home_video')[0].play();*/

    setTimeout(function () {
      $('.home_video')[0].play();
      playVideo = true;
    }, 1500);
  };

  onScroll = () => {
    $(window)
      .scroll(function () {
        if ($(this).scrollTop() > 500) {
          $('.home_video')[0].pause();
        } else if (playVideo === true) {
          $('.home_video')[0].play();
        }
      })
      .scroll();
  };

  onResize = () => {
    $(window)
      .resize(function () {
        winWidth = $(window).width();
      })
      .resize();
  };
}

export default ModuleHeader;
