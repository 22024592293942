import $ from 'jquery';
import CustomAnimation from '../animation/customAnimation';
import Tracking from '../tracking/tracking';

const customAnmation = new CustomAnimation();

class ModuleAbout {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {

  };

  initParallax = () => {
    customAnmation.animateImage('.content_image_about', '#about');
    customAnmation.animateGroup('#about h1, #about p, #about li');
  };
}

export default ModuleAbout;
