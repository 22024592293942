import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class ModuleMap {
  init() {
    this.initListener();
    this.initParallax();
    this.initMap();
  }

  initListener = () => {};

  initParallax = () => {};

  initMap = () => {};
}

export default ModuleMap;
