import $ from 'jquery';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class moduleWerbefilm {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {};

  initParallax = () => {
    customAnmation.animateWerbefilmImage();
    customAnmation.animateText('.werbefilm_logo');
    customAnmation.animateGroup('#werbefilm p');
  };
}

export default moduleWerbefilm;
