import * as OfflinePluginRuntime from 'offline-plugin/runtime';

import './fonts/StayDreaming/StayDreaming.woff';
import './fonts/GilroyRegular/GilroyRegular.woff';
import './fonts/GilroyRegular/GilroyRegular.woff2';
import './fonts/GilroySemiBold/GilroySemiBold.woff';
import './fonts/GilroySemiBold/GilroySemiBold.woff2';
import './fonts/GilroyMedium/GilroyMedium.woff';
import './fonts/GilroyMedium/GilroyMedium.woff2';
import './fonts/GilroyLight/GilroyLight.woff';
import './fonts/GilroyLight/GilroyLight.woff2';

import './index.html';
import './index.scss';
import './scripts/script';

OfflinePluginRuntime.install();
