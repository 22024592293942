import $ from 'jquery';
import Flickity from 'flickity';
import CustomAnimation from '../animation/customAnimation';
import Tracking from '../tracking/tracking';

const customAnmation = new CustomAnimation();
const tracking = new Tracking();
let flickity1;

class moduleWerte {
  init() {
    this.initListener();
    this.initFlickity();
    this.initParallax();
    this.onResize();
  }

  initListener = () => {};

  initFlickity = () => {
    flickity1 = new Flickity('.werte_caroussel', {
      cellAlign: 'left',
      contain: true,
      imagesLoaded: true,
      percentPosition: false,
      freeScroll: false,
      pageDots: true,
      wrapAround: true
    });

    setTimeout(function () {
      flickity1.resize();
    }, 500);

    flickity1.on('change', function (index) {
      tracking.trackWerteChange(index);
    });
  };

  initParallax = () => {
    customAnmation.animateGroup('#werte h1, #werte p, #werte h1');
  };

  onResize = () => {
    $(window)
      .resize(function () {
        flickity1.resize();
      })
      .resize();
  };
}

export default moduleWerte;
