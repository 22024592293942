import 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
import ModulePreloader from './modules/modulePreloader';
import ModuleNavigation from './modules/moduleNavigation';
import Homepage from './pages/homepage';
import Newspage from './pages/newspage';

$(document).ready(function () {
  const modulePreloader = new ModulePreloader();
  modulePreloader.init();

  setTimeout(function () {
    const moduleNavigation = new ModuleNavigation();
    moduleNavigation.init();

    if ($('body').data('pagename') === 'home') {
      const homepage = new Homepage();
      homepage.init();
    } else {
      const newspage = new Newspage();
      newspage.init();
    }
  }, 500);
});
