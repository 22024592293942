import $ from 'jquery';
import Isotope from 'isotope-layout';
import 'isotope-packery';
import 'isotope-cells-by-row';
import CustomAnimation from '../animation/customAnimation';
import Tracking from '../tracking/tracking';

let iso;
let videoSrc;
const projectCount = $('.referenzen_project_item').length;
let activeIndex;
let winWidth;
const customAnmation = new CustomAnimation();
const tracking = new Tracking();
let activeFilter;

function getNextIndex() {
  let nextIndex = activeIndex + 1;
  if (nextIndex >= projectCount) nextIndex = 0;
  return nextIndex;
}
function getPrevIndex() {
  let prevIndex = activeIndex - 1;
  if (prevIndex < 0) prevIndex = projectCount - 1;
  return prevIndex;
}

function isTouchEnabled() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

function getURLParameter(sParam) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
  }
}

class moduleReferenzen {
  init() {
    this.setup();
    this.initListener();
    this.initIsotope();
    this.initParallax();
    this.onResize();
    this.checkDeeplink();
  }

  setup = () => {
    $('.referenzen_thumb').each(function () {
      const imgWidth = $(this).find('img').width();
      const imgHeight = $(this).find('img').height();

      if (imgHeight >= imgWidth) {
        $(this).data('portrait', 'true');
      }
    });

    this.onResize();
  };

  initListener = () => {
    // dropdown
    $(`#${$('.filter_all_label').attr('for')}`).prop('checked', true);

    $('.referenzen_dropdown').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).toggleClass('expanded');
      $(`#${$(e.target).attr('for')}`).prop('checked', true);
      let filterValue = $(e.target).attr('for');

      if (filterValue === undefined) {
        return;
      }

      if (filterValue === 'all') {
        filterValue = '*';
      } else {
        $('.filter_all').addClass('remove');
        filterValue = `.${filterValue}`;
      }

      // tracking
      if ($(this).hasClass('expanded') === false) {
        tracking.trackReferenzenFilter(filterValue);
      }

      iso.arrange({ filter: filterValue });
      activeFilter = filterValue;
    });
    $(document).click(function () {
      $('.referenzen_dropdown').removeClass('expanded');
    });

    // referenz item
    $('.referenzen_project_item').mouseover(function () {
      if (isTouchEnabled() === true || $(this).hasClass('grid-item--text') === true) {
        return;
      }
      $(this).addClass('hover');

      // video
      $('.referenzen_video', $(this))[0].play();
      $(document).on('mousemove', (event) => {
        const title = $('.referenzen_title', $(this));
        const titleCenter = {
          x: title.width() / 2,
          y: title.height() / 2
        };
        title.css('transform', `translate3d(0px, ${event.offsetY - titleCenter.y}px, 0)`);
      });
    });
    $('.referenzen_project_item').mouseout(function () {
      $(document).unbind('mousemove');
      $(this).removeClass('hover');
      if ($(this).hasClass('grid-item--text') === false) {
        $('.referenzen_video', $(this))[0].pause();
      }
    });
    $('.referenzen_project_item').click(function () {
      activeIndex = $('.referenzen_project_item').index(this);
    });

    // modal
    $('.modal_button_next').click(function () {
      activeIndex = getNextIndex();
      const nextDataSrc = $(`.referenzen_project_item:eq(${activeIndex})`).data('src');
      const plattform = $(`.referenzen_project_item:eq(${activeIndex})`).data('platform');

      if (plattform === 'dropbox') {
        videoSrc = nextDataSrc;
      } else {
        videoSrc = `${nextDataSrc}?modestbranding=1&ampshowinfo=0&ampsidedock=0&amp;autoplay=1`;
      }

      console.log(`${activeIndex} ::  ${plattform} // ${videoSrc}`);

      $('#video').attr(
        'src',
        `${videoSrc}`
      );
    });
    $('.modal_button_prev').click(function () {
      activeIndex = getPrevIndex();
      const prevDataSrc = $(`.referenzen_project_item:eq(${activeIndex})`).data('src');
      const plattform = $(`.referenzen_project_item:eq(${activeIndex})`).data('platform');

      if (plattform === 'dropbox') {
        videoSrc = prevDataSrc;
      } else {
        videoSrc = `${prevDataSrc}?modestbranding=1&ampshowinfo=0&ampsidedock=0&amp;autoplay=1`;
      }

      console.log(`${activeIndex} ::  ${plattform} // ${videoSrc}`);

      $('#video').attr(
        'src',
        `${videoSrc}`
      );
    });

    this.videoModal = document.getElementById('videoModal');
    this.videoModal.addEventListener('shown.bs.modal', function (e) {
      const dataSrc = $(e.relatedTarget).data('src');
      const plattform = $(e.relatedTarget).data('platform');

      if (plattform === 'dropbox') {
        videoSrc = dataSrc;
      } else {
        videoSrc = `${dataSrc}?modestbranding=1&ampshowinfo=0&ampsidedock=0&amp;autoplay=1`;
      }

      console.log(`${plattform} // ${videoSrc}`);

      $('#video').attr('src', `${videoSrc}`);
    });
    this.videoModal.addEventListener('hide.bs.modal', function () {
      $('#video').attr('src', '');
    });
  };

  initIsotope = () => {
    iso = new Isotope('.grid', {
      itemSelector: '.referenzen_project_item',
      layoutMode: 'packery',
      originTop: true,
      packery: {
        gutter: '.gutter-sizer'
      },
      percentPosition: true
    });
    setTimeout(function () {
      if ($(window).width() < 768) {
        iso.arrange({ filter: '.socialmedia' });
        activeFilter = 'socialmedia';
      } else {
        iso.arrange({ filter: '*' });
        activeFilter = '*';
      }
    }, 500);
  };

  initParallax = () => {
    customAnmation.animateGroup('#referenzen h1, #referenzen .container .row .col p, .referenzen_project_filter');
    customAnmation.animateText('.referenzen_project_grid');
  };

  onResize = () => {
    $(window)
      .resize(function () {
        winWidth = $(window).width();

        // referenzen item
        if (isTouchEnabled() === true) {
          $('.referenzen_project_item').addClass('isTouch');
        } else {
          $('.referenzen_project_item').removeClass('isTouch');
        }

        $('.referenzen_thumb').each(function (index) {
          if ($(this).data('portrait') === 'true') {
            if ($(window).width() < 768) {
              $(this).addClass('portrait');
            } else {
              $(this).removeClass('portrait');
            }
          }
        });

        // filter update on mobile
        if ($(window).width() < 768) {
          $('.filter_all').addClass('hide');

          if (iso !== undefined) {
            if (activeFilter === '*') {
              iso.arrange({filter: '.socialmedia'});
              activeFilter = 'socialmedia';
            }
          }
        } else {
          $('.filter_all').removeClass('hide');
          if (iso !== undefined) {
            iso.arrange({ filter: '*' });
            activeFilter = '*';
          }
        }
      })
      .resize();
  };

  checkDeeplink = () => {
    const refId = getURLParameter('ref_id');

    if(refId === undefined)
    {
      return;
    }

    $('.referenzen_project_item').each(function () {
      if (refId === $(this).data('video-id')) {
        $(this).trigger('click');
        $('html,body').animate({ scrollTop: $('#referenzen').offset().top }, 500);
      }
    });
  };
}

export default moduleReferenzen;
