import $ from 'jquery';
import CustomAnimation from '../animation/customAnimation';
import Tracking from '../tracking/tracking';

const customAnmation = new CustomAnimation();

class ModuleNews {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {
    /*
    if ($('#news').css('display') === undefined) {
      $('.header_scroll_button').attr('href', '#leistungen');
      $('.header_bubble_news').addClass('display_none');
    } else {
      $('.header_scroll_button').attr('href', '#news');
      $('.header_bubble_news').removeClass('display_none');
    }

     */
  };

  initParallax = () => {
    customAnmation.animateBubble('.news_blue_bg_bubble', '#news', '20%');
    customAnmation.animateGroup('#news h1, #news h2, #news h3, #news h4, #news p');
  };
}

export default ModuleNews;
