import $ from 'jquery';
import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();

class ModuleLeistungen {
  init() {
    this.initListener();
    this.initParallax();
    this.onResize();
  }

  initListener = () => {
    $('.modal_image-text_bottom').each(function () {
      if ($(this).text() === '') {
        $(this).css('display', 'none');
      }
    });

    $('.modal_image-text_left').each(function () {
      if ($(this).text() === '') {
        $(this).css('display', 'none');
      }
    });
  };

  initParallax = () => {
    customAnmation.animateGroup('#leistungen h1, #leistungen p');
    customAnmation.animateImage('.content_image_leistungen', '#leistungen');
    customAnmation.animateLeistungenItems();
  };

  onResize = () => {
    $(window)
      .resize(function () {
        const winWidth = $(window).width();
        const itemCount = $('.leistungen_item').length;

        return;
        if (winWidth > 1200) {
          $('.col_leistungen_item').css('width', '25%');
        } else if (winWidth > 992) {
          $('.col_leistungen_item').css('width', '40%');
        } else if (winWidth > 768) {
          $('.col_leistungen_item').css('width', '50%');
        } else {
          $('.col_leistungen_item').css('width', '50%');
        }

        if (itemCount === 6) {
          if (winWidth > 575) {
            $('.col_leistungen_item').css('width', '33%');
          } else {
            $('.col_leistungen_item').css('width', '50%');
          }
        }
      })
      .resize();
  };
}

export default ModuleLeistungen;
