import $ from 'jquery';
import CustomAnimation from '../animation/customAnimation';
import Tracking from '../tracking/tracking';
import { gsap } from 'gsap';

const customAnmation = new CustomAnimation();

class ModuleAngebot {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {};

  initParallax = () => {
    customAnmation.animateImage('.content_image_angebot', '#angebot');
    customAnmation.animateGroup('#angebot h1, #angebot p, #angebot li, #angebot a');
  };
}

export default ModuleAngebot;
