import $ from 'jquery';
import Tracking from '../tracking/tracking';

let winWidth;
let naviIsOpen = false;
const tracking = new Tracking();

function openNavigation() {
  $('#toggle').addClass('active');
  $('#mainNav').addClass('open');
  $('.top-nav').addClass('show');
  $('.logo').addClass('white');
  // $('body').addClass('body_overflow_hidden');

  tracking.trackNavigation('open');

  $('#toggle').addClass('over_menu');
  $('.language_switcher a').addClass('over_menu');
  $('.header_icon').addClass('over_menu');

  naviIsOpen = true;
}

function closeNavigation() {
  $('#mainNav').addClass('closing');

  setTimeout(function () {
    $('#mainNav').removeClass('open');
    $('.top-nav').removeClass('show');
    $('.logo').removeClass('white');
    $('#toggle').removeClass('active');
    // $('body').removeClass('body_overflow_hidden');

    $('#toggle').removeClass('over_menu');
    $('.language_switcher a').removeClass('over_menu');
    $('.header_icon').removeClass('over_menu');

    tracking.trackNavigation('close');

    naviIsOpen = false;
  }, 500);

  setTimeout(function () {
    $('#mainNav').removeClass('closing');
  }, 1000);
}

$.fn.isInViewport = function () {
  const elementTop = $(this).offset().top;
  const viewportTop = $(window).scrollTop();
  return elementTop > viewportTop + 70;
};

class ModuleNavigation {
  init() {
    this.show();
    this.initListener();
    this.onScroll();
    this.onResize();
  }

  show = () => {
    setTimeout(function () {
      $('.navbar')
        .delay(1500)
        .queue(function () {
          $(this).addClass('show');
          $('#toggle').addClass('show');
        });
    }, 0);
  };

  initListener = () => {
    $('#toggle').click(function () {
      if (naviIsOpen === false) {
        openNavigation();
      } else {
        closeNavigation();
      }
    });

    $('#mainNav').click(function () {
      closeNavigation();
    });

    $('.menu_item').click(function (e) {
      closeNavigation();
    });
    $('.menu_item_scroll').click(function (e) {
      e.preventDefault();
      const aid = $(this).attr('href');
      $('html,body').animate({ scrollTop: $(aid).offset().top }, 500);
    });
  };

  onScroll = () => {
    $(window)
      .scroll(function () {
        $('#toggle').removeClass('blue');
        $('.logo').removeClass('blue');

        // news
        if ($('#news').css('display') !== undefined) {
          if (!$('#news').isInViewport()) {
            $('.logo').addClass('blue');
            $('#toggle').addClass('blue');
          }
        }

        // news
        if ($('#blog').css('display') !== undefined) {
          if (!$('#blog').isInViewport()) {
            $('.logo').addClass('blue');
            $('#toggle').addClass('blue');
          }
        }

        // angrbot
        if ($('#angebot').css('display') !== undefined) {
          if (!$('#angebot').isInViewport()) {
            $('.logo').addClass('blue');
            $('#toggle').addClass('blue');
          }
        }

        // referenzen
        if ($('.referenzen_project_grid').css('display') !== undefined) {
          if (!$('.referenzen_project_grid').isInViewport()) {
            $('.logo').removeClass('blue');
            $('#toggle').removeClass('blue');
          }
        }

        // kunden
        if ($('#kunden').css('display') !== undefined) {
          if (!$('#kunden').isInViewport()) {
            $('.logo').addClass('blue');
            $('#toggle').addClass('blue');
          }
        }

        // maps
        if ($('#googlemap').css('display') !== undefined) {
          if (!$('#googlemap').isInViewport()) {
            $('.logo').addClass('blue');
            $('#toggle').addClass('blue');
          }
        }
      })
      .scroll();
  };

  onResize = () => {
    $(window)
      .resize(function () {
        winWidth = $(window).width();

        const debug = $('.debug');
        debug.text('');

        if (winWidth < 576) {
          debug.text('XS');
        }
        if (winWidth > 576) {
          debug.text('SM');
        }
        if (winWidth > 768) {
          debug.text('MD');
        }
        if (winWidth > 992) {
          debug.text('LG');
        }
        if (winWidth > 1200) {
          debug.text('XL');
        }
        if (winWidth > 1400) {
          debug.text('XXL');
        }
      })
      .resize();
  };
}

export default ModuleNavigation;
