import $ from 'jquery';
import ModuleHeader from '../modules/moduleHeader';
import ModuleLeistungen from '../modules/moduleLeistungen';
import ModuleReferenzen from '../modules/moduleReferenzen';
import ModuleKunden from '../modules/moduleKunden';
import ModuleTeam from '../modules/moduleTeam';
import ModuleWerte from '../modules/moduleWerte';
import ModuleFotos from '../modules/moduleFotos';
import ModuleGreen from '../modules/moduleGreen';
import ModuleContact from '../modules/moduleContact';
import ModuleMap from '../modules/moduleMap';
import ModuleFooter from '../modules/moduleFooter';
import ModuleWerbefilm from '../modules/moduleWerbefilm';
import ModuleModal from '../modules/moduleModal';
import CustomAnimation from '../animation/customAnimation';
import Tracking from '../tracking/tracking';
import ModuleAngebot from '../modules/moduleAngebot';
import ModuleAbout from '../modules/moduleAbout';

class Homepage {
  init() {
    const moduleHeader = new ModuleHeader();
    moduleHeader.init();

    const moduleAngebot = new ModuleAngebot();
    moduleAngebot.init();

    const moduleLeistungen = new ModuleLeistungen();
    moduleLeistungen.init();

    const moduleReferenzen = new ModuleReferenzen();
    moduleReferenzen.init();

    const moduleKunden = new ModuleKunden();
    moduleKunden.init();

    const moduleAbout = new ModuleAbout();
    moduleAbout.init();

    const moduleTeam = new ModuleTeam();
    moduleTeam.init();

    const moduleWerte = new ModuleWerte();
    moduleWerte.init();


    const moduleFotos = new ModuleFotos();
    moduleFotos.init();

    const moduleGreen = new ModuleGreen();
    moduleGreen.init();

    const moduleWerbefilm = new ModuleWerbefilm();
    moduleWerbefilm.init();

    const moduleContact = new ModuleContact();
    moduleContact.init();

    const moduleMap = new ModuleMap();
    moduleMap.init();

    const moduleFooter = new ModuleFooter();
    moduleFooter.init();

    const moduleModal = new ModuleModal();
    moduleModal.init();

    const customAnimation = new CustomAnimation();
    customAnimation.init();

    const tracking = new Tracking();
    tracking.init();
  }
}

export default Homepage;
