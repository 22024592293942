import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import CustomAnimation from '../animation/customAnimation';

const customAnmation = new CustomAnimation();
class moduleFooter {
  init() {
    this.initListener();
    this.initParallax();
  }

  initListener = () => {};

  initParallax = () => {};
}

export default moduleFooter;
