import $ from 'jquery';

const preloaderContainer = $('.preloader_container');
const preloader = $('.preloader');

class ModulePreloader {
  init() {
    setTimeout(function () {
      preloaderContainer.addClass('hide');
    }, 500);

    setTimeout(function () {
      if (window.location.hash !== '') {
        //alert(window.location.hash + ' / ' + $(window.location.hash).offset().top);
        $('html,body').animate({ scrollTop: $(window.location.hash).offset().top }, 0);
      } else {
        window.scrollTo(0, 0);
      }
    }, 1000);

    setTimeout(function () {
      preloader.addClass('hide');
      $('body').removeClass('body_overflow_hidden');
    }, 1500);

    setTimeout(function () {
      preloader.fadeOut(0);
    }, 2500);
  }
}

export default ModulePreloader;
