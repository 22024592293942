import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class ModuleModal {
  init() {}

  initListener = () => {};

  onResize = () => {
    $(window)
      .resize(function () {})
      .resize();
  };
}

export default ModuleModal;
